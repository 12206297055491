<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'SystemSettingsHome',
              }"
            >全系統管理</b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'OrganizationList',
              }"
            >組織管理</b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ title }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center justify-content-between">
          <h4 class="font-weight-bold">{{ title }}</h4>
          <div class="d-flex">
            <b-button v-if="isShow" class="mr-2" @click="showSwalForResetOrg">重設出廠狀態並保留設定值</b-button>
            <b-button v-if="isShow" @click="showSwalForSetDefaultRole">修復並保留設定值</b-button>
          </div>
        </div>
      </div>

      <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
        <section class="row mb-8">
          <div class="col-12 col-xl-8">
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="組織名稱"
              label-for="name"
            >
              <b-form-input
                id="name"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="state.form.name"
                :state="v$.form.name.$error ? false : null"
                :disabled="isShow"
              ></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.name.$error">此欄位為必填</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="組織代碼"
              label-for="code"
            >
              <b-form-input
                id="code"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="state.form.code"
                :state="v$.form.code.$error ? false : null"
                :disabled="isShow"
              ></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.code.$error">此欄位為必填</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              v-if="isShow"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="組織編號"
              label-for="code"
            >
              <b-form-input
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                :value="state.form.id"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="是否啟用"
              label-for="is_enabled"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="state.form.is_enabled"
                name="is_enabled"
                :value="1"
                :unchecked-value="0"
                :disabled="isShow"
              ></b-form-checkbox>
            </b-form-group>
          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel">{{ isShow ? '返回' : '取消' }}</b-button>
        <b-overlay
          v-if="!isShow"
          :show="isSubmmiting"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button variant="success" @click="handleSubmit">
            {{
              isEdit ? "確認修改" : "確認新增"
            }}
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import { reactive } from "@vue/composition-api";
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import organizationApi from "../../../apis/organization";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";

export default {
  setup() {
    const state = reactive({
      form: {
        name: "",
        code: "",
        is_enabled: 0,
      },
    });
    const rules = {
      form: {
        name: { required },
        code: { required },
      },
    };

    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      isLoading: false,
      isSubmmiting: false,
      form: {
        name: "",
        code: "",
        is_enabled: 0
      }
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        code: { required },
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    orgId() {
      return this.$route.params.orgId;
    },
    isEdit() {
      return this.$route.name === "OrganizationEdit";
    },
    isShow() {
      return this.$route.name === "OrganizationShow";
    },
    title() {
      switch (true) {
        case this.isEdit:
          return '組織編輯';
        case this.isShow:
          return '檢視組織';
        default:
          return '新增組織';
      }
    },
  },
  mounted() {
    if (!this.checkPermission([consts.ORGANIZATION_MODIFY])) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33"
        })
        .then(() => {
          this.$router.push({ name: "OrganizationList" });
        });
    } else if (this.orgId) {
      this.fetchOrganization();
    }
  },
  methods: {
    async fetchOrganization() {
      try {
        this.isLoading = true;
        const { data } = await organizationApi.getOrganizationById(this.orgId);
        const { id, name, code, is_enabled } = data.data;

        if (this.isShow || this.isEdit) {
          this.form = {
            ...this.form,
          };
          this.state.form = {
            id,
            name,
            code,
            is_enabled
          };
        }
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    async showSwalForResetOrg() {
      this.$swal
        .fire({
          html: `
            <p>確定要重設出為廠狀態並保留目前設定值？</p>
            <p>將會移除相關權限、範本、模組、對話關鍵字。</p>
          `,
          type: "warning",
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonText: "確定重設",
        })
        .then(async (result) => {
          if (result.value) {
            this.resetOrg();
          }
        });
    },
    async resetOrg() {
      try {
        this.isSubmmiting = true;
        await organizationApi.resetOrg(this.orgId);
        this.showSuccessPopup("設定成功");
      } catch (error) {
        this.showErrorMessage(error);
      }
      this.isSubmmiting = false;
    },
    async showSwalForSetDefaultRole() {
      this.$swal
        .fire({
          html: `
            <p>確定要修復並保留目前設定值？</p>
            <p>將會檢查並修復組織初始化相關權限、範本、模組、對話關鍵字，不會移除相關設定。</p>
          `,
          type: "warning",
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonText: "確定修復",
        })
        .then(async (result) => {
          if (result.value) {
            this.setDefaultRole();
          }
        });
    },
    async setDefaultRole() {
      try {
        this.isSubmmiting = true;
        await organizationApi.setDefaultRole(this.orgId);
        this.showSuccessPopup("設定成功");
      } catch (error) {
        this.showErrorMessage(error);
      }
      this.isSubmmiting = false;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;
      if (this.isEdit) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    },
    async handleUpdate() {
      try {
        this.isSubmmiting = true;
        await organizationApi.updateOrganization(this.orgId, {
          ...this.form,
          ...this.state.form,
        });
        this.showSuccessPopup("修改成功");
      } catch (error) {
        this.showErrorMessage(error);
      }
      this.isSubmmiting = false;
    },
    async handleCreate() {
      try {
        this.isSubmmiting = true;
        await organizationApi.createOrganization({
          ...this.form,
          ...this.state.form,
        });
        this.showSuccessPopup("新增成功");
      } catch (error) {
        this.showErrorMessage(error);
      }
      this.isSubmmiting = false;
    },
    handleCancel() {
      this.$router.push({ name: "OrganizationList" });
    },
    showSuccessPopup(message) {
      this.$swal
        .fire({
          type: "success",
          text: message,
        })
        .then(() => {
          this.$router.push({ name: "OrganizationList" });
        });
    },
    showErrorMessage(error) {
      const { status, data } = error.response;

      if (status && status === 422 && data.message) {
        const html = Object.values(data.message)
          .map((m) => m[0])
          .join("<br/>");
        this.$swal.fire({
          type: "error",
          html,
        });
      }
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    }
  },
};
</script>
